<template>
  <v-container>
    <v-card outlined>
      <v-card-title class="justify-center"
        >Error 404 - page not found</v-card-title
      >
      <v-row>
        <v-col cols="6">
          <v-card-text>
            <router-link to="/">Go To Search Page</router-link>
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <v-card-text>
            <router-link to="/home">Home</router-link>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "FourOhFour",
};
</script>
